@import 'config.scss';

.EmbedForm {
  position: relative;

  &.left {
    .triangle {
      left: 0;
    }

    .tetris {
      align-self: flex-start;
      margin-top: -5px;
      margin-left: -80px;
    }
  }

  &.right {
    .triangle {
      right: 0;
    }

    .tetris {
      align-self: flex-end;
      margin-top: -5px;
      margin-right: -80px;
    }
  }
}

.triangle {
  display: none;
  position: absolute;
  top: 0;
  width: 50%;
  max-width: 600px;
  z-index: -1;

  @include breakpoint($md) {
    display: block;
  }
}

.smallInnerWrapper {
  display: flex;
  flex-direction: column;
}

.tetris {
  display: none;

  @include breakpoint($md) {
    display: block;
  }
}

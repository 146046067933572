@import 'config.scss';

.ContactForm {
  background-color: $white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 17px;
  padding: 40px 37px;
  box-sizing: border-box;
  height: fit-content;

  @include breakpoint($md) {
    padding: 40px 73px;
  }

  iframe {
    width: 100% !important;
  }
}

.formIntro {
  margin-bottom: 40px;
}
@import 'config.scss';

.Triangle {
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  &.withMobileDots {
    display: block;
  }

  &.top-right {
    .image {
      transform: rotate(180deg);
    }

    .dots {
      transform: rotate(180deg);
      margin-left: 70px;
      margin-bottom: 30px;
    }
  }

  &.top-right.reversed {
    .wrapper {
      flex-direction: row;
    }
  }

  &.top-left,
  &.top-right.reversed {
    .image {
      transform: rotate(90deg);
    }
  }

  &.bottom-left {
    .wrapper {
      flex-direction: column-reverse;
    }

    .image {
      transform: rotate(0);
    }

    .dots {
      margin-top: 30px;
    }
  }

  &.bottom-right {
    .wrapper {
      flex-direction: column-reverse;
    }

    .image {
      transform: rotate(270deg);
    }

    .dots {
      margin-top: 30px;
      margin-left: 30px;
      transform: rotateY(180deg);
    }
  }

  .dots {
    display: none;
    max-width: 140px;

    @include breakpoint($md) {
      display: block;
    }
  }
}

@import 'config.scss';

.Tetris {
  display: block;
  width: 60px;
  height: 60px;

  @include breakpoint($md) {
    width: 90px;
    height: 90px;
  }

  &.purple {
    :global([class$='tetris-dark-path']) {
      fill: darken($purple-500, 5);
    }
    :global([class$='tetris-light-path']) {
      fill: $purple-500;
    }
  }

  &.yellow {
    :global([class$='tetris-dark-path']) {
      fill: darken($golden-yellow, 10);
    }
    :global([class$='tetris-light-path']) {
      fill: $golden-yellow;
    }
  }

  &.blue {
    :global([class$='tetris-dark-path']) {
      fill: darken($anakiwa, 35);
    }
    :global([class$='tetris-light-path']) {
      fill: $anakiwa;
    }
  }

  &.green {
    :global([class$='tetris-dark-path']) {
      fill: darken($pastel-green, 10);
    }
    :global([class$='tetris-light-path']) {
      fill: $pastel-green;
    }
  }

  &.white {
    :global([class$='tetris-dark-path']) {
      fill: rgba(255, 255, 255, 0.5);
    }
    :global([class$='tetris-light-path']) {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
}

.svg {
  width: 100%;
  height: 100%;
}

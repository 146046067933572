@import 'config.scss';

.LabelTitleText {
  opacity: 0;
  width: 100%;

  .cliContainer {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  &.noAnimation {
    opacity: 1;

    .word,
    .button {
      opacity: 1;
    }
  }

  &.center {
    text-align: center;

    .label,
    .title,
    .text,
    .link {
      width: 100%;
      text-align: center;
    }

    .label,
    .title,
    .text {
      margin-left: auto;
      margin-right: auto;
    }

    .text {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
    }

    .links {
      justify-content: center;
    }
  }

  &.small {
    .title {
      margin-bottom: 12px;
    }

    .text {
      margin-bottom: 39px;
    }
  }

  &.ctaBanner {
    .title {
      margin-bottom: 24px;
    }

    .text {
      margin-bottom: 40px;
    }
  }

  &.mobileCenter {
    .label,
    .title,
    .text,
    .link {
      text-align: center;

      @include breakpoint($md) {
        text-align: left;
      }
    }

    .links {
      justify-content: center;

      @include breakpoint($md) {
        justify-content: flex-start;
      }
    }
  }
}

.label,
.title,
.text {
  text-align: left;
  transform: translateZ(1000px);
}

.label {
  margin-bottom: 40px;
}

.title {
  position: relative;
  margin-bottom: 35px;
}

.word,
.button {
  opacity: 0;
  will-change: opacity, transform;
}

.titleContainer {
  position: relative;
}

.text {
  padding: 0 6px;
  margin-bottom: 57px;

  @include breakpoint($md) {
    padding: 0;
  }
}

.label,
.title,
.text,
.links {
  &:last-child {
    margin-bottom: 0px;
  }
}

.links {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  flex-direction: column;

  @include breakpoint($sm) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .alignUnderButton {
    flex-basis: 100%;
    margin-top: 25px;

    @include breakpoint($md) {
      margin-top: 35px;
    }

    .smallTextWithLink {
      display: inline-block;
      width: auto;
    }
  }

  .link {
    text-indent: initial;
  }
}

.button {
  width: 100%;

  @include breakpoint($sm) {
    width: auto;
  }
}

.link {
  margin: 0 auto 15px 0;
  width: 100%;

  @include breakpoint($sm) {
    width: auto;
    margin-bottom: 0px;
  }

  &:last-child {
    margin-bottom: 0px;

    @include breakpoint($sm) {
      margin-right: 0px;
    }
  }
}
